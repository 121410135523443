import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from '../stores/auth';
const Home = () => import("/src/views/Home.vue");
const ResetPassword = () => import("/src/views/PasswordReset.vue");
const UnsubscribeEmails = () => import("/src/views/UnsubscribeEmails.vue");
const ChangeEmail = () => import("/src/views/ChangeEmail.vue");

const routes = [
  {
    path: "/reset_password/:token/:id",
    component: ResetPassword,
  },
  {
    path: "/unsubscribe/:id",
    component: UnsubscribeEmails,
  },
  {
    path: "/change_email/:token/:id",
    component: ChangeEmail,
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/Auth.vue"),
    meta: { ifAuthenticated: '/redeem' }
  },
  {
    path: "/redeem",
    name: "RedeemCoupon",
    component: () => import("../views/RedeemCoupon.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/:pathMatch(.*)*",
    component: Home,
  },
  {
    path: '/auth/google/callback',
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/auth/facebook/callback',
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/auth/apple/callback',
    component: () => import('../views/Auth.vue')
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  
  if (to.meta.requiresAuth && !authStore.isAuthenticated()) {
    next('/auth');
  } else if (to.meta.ifAuthenticated && authStore.isAuthenticated()) {
    next(to.meta.ifAuthenticated);
  } else {
    next();
  }
});

export default router;
