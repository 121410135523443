{
  "unsubscribe_title": "Unsubscribe from emails",
  "unsubscribe_message": "Sorry to see you go.",
  "unsubscribe": "Unsubscribe",
  "cancel": "Cancel",
  "passwordResetSuccess": "Your password has been changed successfully.",
  "passwordResetError": "Unfortunately, there was a problem",
  "passwordResetTitle": "Set a new password",
  "new_password": "New password",
  "confirm_password": "Confirm new password",
  "save": "Save",
  "error": "Unfortunately, there was a problem",
  "unsubscribeSuccess": "The action was successful",
  "emailChangeLoading": "Email change loading...",
  "emailChangeSuccess": "The email was successfully changed",
  "emailChangeError": "Unfortunately, there was a problem changing the email",
  "auth": {
    "hundeoLogo": "hundeo logo",
    "continueWithGoogle": "Continue with Google",
    "continueWithFacebook": "Continue with Facebook",
    "continueWithApple": "Continue with Apple",
    "termsIntro": "By continuing, you agree to Hundeo's",
    "termsOfService": "Terms of Service",
    "and": "and",
    "privacyPolicy": "Privacy Policy",
    "email": "Email",
    "password": "Password",
    "emailPlaceholder": "Enter your email",
    "passwordPlaceholder": "Enter your password",
    "loginButton": "Login",
    "orContinueWith": "Or continue with"
  },
  "coupon": {
    "hundeoLogo": "hundeo logo",
    "welcome": "Welcome",
    "enterCode": "Enter your coupon code",
    "codePlaceholder": "Enter code here",
    "redeemButton": "Redeem Coupon",
    "backToApp": "Back to hundeo app",
    "success": "Coupon successfully redeemed!",
    "error": "Failed to redeem coupon. Please try again.",
    "errorLoadingAccount": "Failed to load account information",
    "invalidCode": "Invalid coupon code"
  },
  "common": {
    "logout": "Logout"
  }
}
