{
  "unsubscribe_title": "Se désabonner des e-mails",
  "unsubscribe_message": "Désolé de vous voir partir.",
  "unsubscribe": "Se désabonner",
  "cancel": "Annuler",
  "passwordResetSuccess": "Votre mot de passe a été modifié avec succès.",
  "passwordResetError": "Malheureusement, il y a eu un problème",
  "passwordResetTitle": "Définir un nouveau mot de passe",
  "new_password": "Nouveau mot de passe",
  "confirm_password": "Confirmer le nouveau mot de passe",
  "save": "Enregistrer",
  "error": "Malheureusement, il y a eu un problème",
  "unsubscribeSuccess": "L'action a été réussie",
  "emailChangeLoading": "Changement d'e-mail en cours...",
  "emailChangeSuccess": "L'e-mail a été changé avec succès",
  "emailChangeError": "Malheureusement, il y a eu un problème lors du changement d'e-mail"
}
