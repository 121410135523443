{
  "unsubscribe_title": "Darse de baja de los correos electrónicos",
  "unsubscribe_message": "Lamentamos verte partir.",
  "unsubscribe": "Darse de baja",
  "cancel": "Cancelar",
  "passwordResetSuccess": "Tu contraseña ha sido cambiada exitosamente.",
  "passwordResetError": "Desafortunadamente, hubo un problema",
  "passwordResetTitle": "Establecer una nueva contraseña",
  "new_password": "Nueva contraseña",
  "confirm_password": "Confirmar nueva contraseña",
  "save": "Guardar",
  "error": "Desafortunadamente, hubo un problema",
  "unsubscribeSuccess": "La acción fue exitosa",
  "emailChangeLoading": "Cambio de correo electrónico en proceso...",
  "emailChangeSuccess": "El correo electrónico fue cambiado exitosamente",
  "emailChangeError": "Desafortunadamente, hubo un problema al cambiar el correo electrónico"
}
