{
  "unsubscribe_title": "Annulla l'iscrizione alle email",
  "unsubscribe_message": "Dispiace vederti andare.",
  "unsubscribe": "Annulla iscrizione",
  "cancel": "Annulla",
  "passwordResetSuccess": "La tua password è stata cambiata con successo.",
  "passwordResetError": "Purtroppo, si è verificato un problema",
  "passwordResetTitle": "Imposta una nuova password",
  "new_password": "Nuova password",
  "confirm_password": "Conferma nuova password",
  "save": "Salva",
  "error": "Purtroppo, si è verificato un problema",
  "unsubscribeSuccess": "L'azione è stata eseguita con successo",
  "emailChangeLoading": "Cambio email in corso...",
  "emailChangeSuccess": "L'email è stata modificata con successo",
  "emailChangeError": "Purtroppo, si è verificato un problema nel cambiare l'email"
}
