import { defineStore } from "pinia";
import { ref } from "vue";

export const useAuthStore = defineStore("auth", () => {
  const user = ref(null);
  const token = ref(localStorage.getItem("token"));

  const setAuth = (userData, authToken) => {
    user.value = userData;
    token.value = authToken;
    localStorage.setItem("token", authToken);
  };

  const logout = () => {
    user.value = null;
    token.value = null;
    localStorage.removeItem("token");
  };

  const isAuthenticated = () => {
    return !!token.value;
  };

  const fetchUserData = async () => {
    if (!token.value) return null;

    try {
      const response = await fetch(
        `https://app.hundeo.app/api/v1/user/webAuth/me/${token.value}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const userData = await response.json();
      user.value = userData;
      return userData;
    } catch (error) {
      console.error("Error fetching user data:", error);
      logout(); // Clear auth state if we can't fetch user data
      return null;
    }
  };

  // Initialize user data if we have a token but no user data
  if (token.value && !user.value) {
    fetchUserData();
  }

  return {
    user,
    token,
    setAuth,
    logout,
    isAuthenticated,
    fetchUserData,
  };
});
