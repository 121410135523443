{
  "unsubscribe_title": "E-Mails abbestellen",
  "unsubscribe_message": "Schade, dass sie sich abmelden wollen.",
  "unsubscribe": "Abbestellen",
  "cancel": "Abbrechen",
  "passwordResetSuccess": "Dein Password wurde erfolgreich geändert.",
  "passwordResetError": "Leider gab es ein Problem",
  "passwordResetTitle": "Neues Passwort festlegen",
  "new_password": "Neues Passwort",
  "confirm_password": "Neues Passwort bestätigen",
  "save": "Speichern",
  "error": "Leider gab es ein Problem",
  "unsubscribeSuccess": "Die Aktion war erfolgreich",
  "emailChangeLoading": "E-Mail Änderung lädt...",
  "emailChangeSuccess": "Die E-Mail wurde erfolgreich geändert.",
  "emailChangeError": "Leider gab es ein Problem bei der Änderung der E-Mail",
  "auth": {
    "hundeoLogo": "hundeo Logo",
    "continueWithGoogle": "Weiter mit Google",
    "continueWithFacebook": "Weiter mit Facebook",
    "continueWithApple": "Weiter mit Apple",
    "termsIntro": "Durch die weitere Nutzung stimmst du den",
    "termsOfService": "Nutzungsbedingungen",
    "and": "und der",
    "privacyPolicy": "Datenschutzerklärung",
    "email": "E-Mail",
    "password": "Passwort",
    "emailPlaceholder": "E-Mail eingeben",
    "passwordPlaceholder": "Passwort eingeben",
    "loginButton": "Anmelden",
    "orContinueWith": "Oder weiter mit"
  },
  "coupon": {
    "hundeoLogo": "hundeo Logo",
    "welcome": "Willkommen",
    "enterCode": "Gib deinen Gutscheincode ein",
    "codePlaceholder": "Code hier eingeben",
    "redeemButton": "Gutschein einlösen",
    "backToApp": "Zurück zur hundeo App",
    "success": "Gutschein erfolgreich eingelöst!",
    "error": "Fehler beim Einlösen des Gutscheins. Bitte versuche es erneut.",
    "errorLoadingAccount": "Fehler beim Laden der Kontoinformationen",
    "invalidCode": "Ungültiger Gutscheincode"
  },
  "common": {
    "logout": "Logout"
  }
}
