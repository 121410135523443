import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import "./index.css";
import App from "./App.vue";
import router from "./router/index";

import de from "./assets/locales/de.json";
import en from "./assets/locales/en.json";
import fr from "./assets/locales/fr.json";
import it from "./assets/locales/it.json";
import es from "./assets/locales/es.json";

const locale = (navigator.language || navigator.userLanguage).split("-")[0];

const i18n = createI18n({
  locale: locale,
  fallbackLocale: "en",
  allowComposition: true, // you need to specify that!
  messages: {
    en,
    de,
    fr,
    it,
    es,
  },
});

const pinia = createPinia();

const app = createApp(App);

app.use(pinia).use(router).use(i18n).mount("#app");
